<template>
  <div class="p-client-merge">
    <!--    添加返回-->
    <go-back-item></go-back-item>
    <section class="c-merge-client_list">
      <p class="c-merge-client_title">待合并客户列表</p>
      <div class="c-merge-client_content">
        <grid
          ref="list"
          :columns="columns"
          :datas="itemSource"
          :selection="false"
          :suppressDragLeaveHidesColumns="true"
          :suppressPaginationPanel="false"
          domLayout="autoHeight"
          headerHeight="35"
          type="base">
        </grid>
      </div>
    </section>
    <section class="c-merge-client_box">
      <p class="c-merge-client_title">合并后客户信息</p>
      <div class="c-merge-client_content">
        <znl-input
          v-model="editItem.CompanyName"
          border
          class="znl-input"
          placeholder=""
          title="客户名称: "
          title-width="100px"
          width="300px">
        </znl-input>
        <znl-input
          v-model="editItem.CompanyCode"
          border
          class="znl-input"
          placeholder=""
          title="客户编码: "
          title-width="100px"
          width="300px">
        </znl-input>
        <znl-input
          v-model="editItem.Phone"
          border
          class="znl-input"
          placeholder=""
          title="固定电话: "
          title-width="100px"
          width="300px">
        </znl-input>
        <znl-input
          v-model="editItem.Fax"
          border
          class="znl-input"
          placeholder=""
          title="传真: "
          title-width="100px"
          width="300px">
        </znl-input>
      </div>
      <div class="c-merge-client_content">
        <znl-input
          v-model='editItem.FullAddress'
          border
          class="znl-input"
          placeholder=""
          title="客户地址: "
          title-width="100px"
          width="610px">
        </znl-input>
        <znl-input
          v-model="editItem.ReceivingAddress"
          border
          class="znl-input"
          placeholder=""
          title="收货地址: "
          title-width="100px"
          width="610px">
        </znl-input>
      </div>
      <div class="c-merge-client_content">
        <znl-input
          v-model="editItem.Introduction"
          border
          class="znl-input"
          placeholder=""
          title="客户简历: "
          title-width="100px"
          type="textarea"
          width="610px">
        </znl-input>
        <znl-input
          v-model="editItem.Remark"
          border
          class="znl-input"
          placeholder=""
          title="客户备注: "
          title-width="100px"
          type="textarea"
          width="610px">
        </znl-input>
      </div>
      <div class="c-merge-client_content c-merge-client_attribute">
        <p v-for="(item, index) in attributeList" :key="index"
           style="display: inline-block">
          <znl-input v-model="item.selectValue"
                     :select-options="item.lines"
                     :title="`${item.name}:`"
                     border
                     clearable
                     form-type="select"
                     placeholder='请选择'
                     title-width="100px"
                     width="300px">
          </znl-input>
        </p>
      </div>


      <div class="c-merge-client_content">
        <znl-input
          v-model="editItem.RegisteredCapital"
          border
          class="znl-input"
          placeholder=""
          title="注册资金: "
          title-width="100px"
          width="300px">
        </znl-input>
        <znl-input
          v-model="editItem.BusinessLicense"
          border
          class="znl-input"
          placeholder=""
          title="营业执照号: "
          title-width="100px"
          width="300px">
        </znl-input>
      </div>

      <div class="c-merge-client_content">
        <znl-input
          v-model="editItem.CorporateName"
          border
          class="znl-input"
          placeholder=""
          title="对公名称: "
          title-width="100px"
          width="300px">
        </znl-input>
        <znl-input
          v-model="editItem.OpenAccountPhone"
          border
          class="znl-input"
          placeholder=""
          title="开户电话: "
          title-width="100px"
          width="300px">
        </znl-input>
        <znl-input
          v-model="editItem.CorporateAccount"
          border
          class="znl-input"
          placeholder=""
          title="对公帐号: "
          title-width="100px"
          width="300px">
        </znl-input>
        <znl-input
          v-model="editItem.BankName"
          border
          class="znl-input"
          placeholder=""
          title="开户银行: "
          title-width="100px"
          width="300px">
        </znl-input>
      </div>

      <div class="c-merge-client_content">
        <znl-input
          v-model="editItem.ClearingForm"
          border
          class="znl-input"
          placeholder=""
          title="结算方式: "
          title-width="100px"
          width="300px">
        </znl-input>
        <znl-input
          ref="currencyCode_dom"
          v-model="editItem.CurrencyCode"
          :select-options="currencyOption"
          border
          class="znl-input"
          clearable
          form-type="select"
          placeholder=""
          title="结算币别: "
          title-width="100px"
          width="300px">
        </znl-input>
        <znl-input
          ref="taxRate_dom"
          v-model="editItem.TaxRate"
          :select-options="taxRatesOptions"
          border
          class="znl-input"
          clearable
          form-type="select"
          placeholder=""
          title="税率: "
          title-width="100px"
          width="300px">
        </znl-input>
        <znl-input
          v-model="editItem.BillingRemark"
          border
          class="znl-input"
          placeholder=""
          title="开票备注: "
          title-width="100px"
          width="300px">
        </znl-input>
      </div>
    </section>
    <section class="m-margin--top-10 p-merge-client-footer">
      <el-button :loading="saveLoading" type="primary" @click="onSave">保存</el-button>
    </section>
  </div>
</template>

<script>
import {mergeClientColumns} from "./Modules/List/config";
import {getMergeClient, editMergeClient} from "@/api/client";
import {getTagAndAttribute} from "@/api/client";

import {mapGetters} from 'vuex'

export default {
  name: "mergeClient_ref",
  computed: {
    ...mapGetters([
      'currencyOption',
      'taxRatesOptions',
    ])
  },
  data() {
    return {
      visible: false,
      columns: mergeClientColumns,
      editItem: {
        Ids: [], //选择的全部客户ID
        ChoiceCompanyId: 0, //选择的哪个公司ID（选择的公司名）
        ChoiceAdressId: 0, //选择的送货地址ID（公司ID）
        ChoiceAttributeId: 0, //选择的客户属性ID（公司ID）
        CompanyCode: null, //客户编码
        CompanyName: null, //公司名称
        Phone: null, //电话
        Fax: null, //公司传真
        Province: null, //省份
        City: null, //城市
        Area: null, //区域
        Address: null, //公司地址
        Introduction: null, //简介
        Remark: null, //备注
        Mobile: null, //手机号
        RegisteredCapital: null, //注册资金
        BusinessScope: null, //经营范围
        BusinessLicense: null, //营业执照号
        VATNumber: null, //增值税号
        CorporateName: null, //对公名称
        CorporateAccount: null, //对公账号
        OpenAccountPhone: null, //开户电话
        BankAccount: null,
        BankName: null, // 开户银行
        IsInvoice: true, //是否开票
        ClearingForm: null, //结算方式
        TaxAddress: null, //开票地址， 寄送信息
        CurrencyCode: null, //使用币种
        BillingRemark: null,   //开单备注
        TaxRate: null,           // 税率
        FullAddress: null,      // 客户地址
        ReceivingAddress: null, // 收货地址
      },
      attributeList: [],
      itemSource: [],
      initAttribute: [],
      // currencyOption: [],
      // taxRates: []

      selectCodes: [],
      saveLoading: false
    };
  },
  methods: {
    async onInit() {
      const {query: {ids}} = this.$route
      const data = await getMergeClient(ids)
      this.editItem.Ids = ids
      data.forEach((item, index) => {
        item.rowIndex = index + 1
        item.FullAddressInfo = {
          Province: item.Province,
          City: item.City,
          Area: item.Area
        }
        item.LicenseMusterInfo = {
          RegisteredCapital: item.RegisteredCapital,
          BusinessLicense: item.BusinessLicense,
          BusinessScope: item.BusinessScope
        }
        item.InvoicingMusterInfo = {
          VATNumber: item.VATNumber,
          CorporateName: item.CorporateName,
          CorporateAccount: item.CorporateAccount,
          OpenAccountPhone: item.OpenAccountPhone,
          BankAccount: item.BankAccount,
          BankName: item.BankName
        }
        item.SettlementMusterInfo = {
          ClearingForm: item.ClearingForm,
          CurrencyCode: item.CurrencyCode,
          TaxRate: item.TaxRate,
          BillingRemark: item.BillingRemark
        }
        item.CompanyNameInfo = {
          CompanyCode: item.CompanyCode,
          Remark: item.Remark,
          Introduction: item.Introduction
        }
        Object.keys(item).forEach(key => {
          const code = `isSelect${key}`
          item[code] = false
          item.isAllCheck = false
        })

        item.ReceivingAddress = '前端自定地址'
      })
      this.itemSource = data

      this.$nextTick(async () => {
        await this.initColumns()
      })

      // 选择的字段集合
      const selectCodes = this.columns.filter(item => (item.field !== 'rowIndex' && item.field !== 'isAllCheck'))
      this.selectCodes = selectCodes.map(item => `isSelect${item.field}`)

      // 初始化标签
      await this.initTagAndAttribute()
      await this.setData([])
    },


    // 标签 公司属性 处理
    async getAllTagAndAttribute() {
      return await getTagAndAttribute()
    },
    async initTagAndAttribute() {
      const data = await this.getAllTagAndAttribute()
      const attributeList = data.filter(item => item["DictItemType"] === 'Attribute')
      // 储存原始数据
      this.initAttribute = this.setTagAndAttributeList(attributeList)
    },
    setTagAndAttributeList(list) {
      // 整理数据
      return list.map((item, index) => Object.assign({}, {
        indexId: (index + 1),
        id: item['DictItemId'],
        name: item['DictItemName'],
        type: item['DictItemType'],
        code: item['DictItemCode'],
        selectValue: '',
        lines: item['DictItemLines'].map(line => Object.assign({}, {
          lineId: line['DictItemLineId'],
          value: line['DisctItemLineName'],
          key: line['DisctItemLineCode']
        }))
      }))
    },
    onMergeArr(targetData, paramsData) {
      return paramsData.reduce((acc, cur) => {
        const target = acc.find(e => e.code === cur.code)
        if (target) {
          Object.assign(target, cur)
        }
        return acc
      }, targetData)
    },

    async setData(list) {
      const newAttributeList = this.initAttribute.map(item => Object.assign({}, item))

      let attributeList = []

      if (list.length !== 0) {
        attributeList = list.map(item => Object.assign({}, {
          code: item.Code,
          selectValue: item['LineValue']
        }))
      }

      this.attributeList = await this.onMergeArr(newAttributeList, attributeList)
    },

    // 数据处理
    setEditAloneItem(item, field, isCheck) {

      console.log(item, field, isCheck)

      const waitOrder = ['FullAddress', 'LicenseMuster', 'InvoicingMuster', 'SettlementMuster', 'CompanyName']


      if (waitOrder.includes(field)) {
        const key = `${field}Info`
        const selectInfo = item[key]
        Object.keys(selectInfo).forEach(key => {
          this.editItem[key] = isCheck ? selectInfo[key] : null
        })

      }

      if (field === 'AttributeMuster') {
        this.setData(item['CustomerDictItemMuster'])
        this.editItem.ChoiceAttributeId = isCheck ? item['CompanyID'] : 0
      }

      if (field === 'CompanyName') {
        this.editItem.ChoiceCompanyId = isCheck ? item['CompanyID'] : 0
      }

      if (field === 'ReceivingAddress') {
        this.editItem.ChoiceAdressId = isCheck ? item['ReceivingAddressId'] : 0
      }


      this.editItem[field] = isCheck ? item[field] : null

    },
    // 全选
    setAllSelect(selectItem, isCheck) {
      this.columns.forEach(item => {
        
        if (item.field !== 'rowIndex' && item.field !== 'isAllCheck') {
          this.setEditAloneItem(selectItem, item.field, isCheck)
        }
      })
    },

    async onSave() {

      this.saveLoading = true
      const {editItem, attributeList} = this
      const sendData = Object.assign({}, editItem)

      sendData.AttributeInfoSend = attributeList.map(item => {
        return {
          ItemCode: item.code,
          ItemValue: item.selectValue
        }
      })

      try {
        await editMergeClient(editItem)
        this.$message.success('客户合并成功')
        this.saveLoading = false
      } finally {
        this.saveLoading = false
      }


      this.$router.push({
        name: 'clientList'
      })

      await this.$store.dispatch('tagsView/delView', this.$route)
    },

    onSetSelect(data) {

      /*
        code === isAllCheck 检查全选   code === isAllCheck 检查单选
        1. 全选
           检查 操作行 isCheck 状态
           isCheck === true   选中当前行的数据 清除其他的行数
           isCheck === false  清除当前行数据 获取其他数据

        2. 单选
           2.1 检查 有没有 全选
               全选状态   1. 操作单元 属于     全选行  改变该操作单元数据
                         2. 操作耽于 不属于    全选行  取消全选 改变该操作单元数据
               无全选状态 检查 该操作单元 isCheck
                         1. isCheck == true  加载当前行数据  取消其他操作单元的数据
                         2. isCheck == false 取消操作单元的数据

      */

      const {selectCodes} = this

      const {code, index, isCheck} = data

      const isAllCheck = code === 'isAllCheck'

      if (isAllCheck) {
        const selectItems = this.itemSource.find(item => item.rowIndex === index)
        console.log(selectItems)
        switch (isCheck) {
          case true:
            this.itemSource.forEach(line => {
              Object.keys(line).forEach(key => {
                line[`isSelect${key}`] = line.rowIndex === index
              })
              line.isAllCheck = line.rowIndex === index
            })

            // 处理数据
            break

          case false:
            Object.keys(selectItems).forEach(key => {
              selectItems[`isSelect${key}`] = false
            })

            // 处理数据
            break

          default:
            break
        }

        this.setAllSelect(selectItems, isCheck)
      }

      if (!isAllCheck) {
        const selectItem = this.itemSource.find(item => item.rowIndex === index)

        // 选择行不是全选
        if (!selectItem.isAllCheck) {
          this.itemSource.forEach(line => {
            line.isAllCheck = false
          })
        } else {
          // 如果全选行，单击后肯定就是false
          selectItem.isAllCheck = false
        }

        if (isCheck) {
          this.itemSource.forEach(line => {
            line[`isSelect${code}`] = line.rowIndex === index;
          })

          let allSelect = false

          for (let i = 0; i < selectCodes.length; i++) {
            const code = selectCodes[i]
            const noSelect = selectItem[code]
            if (!noSelect) {
              allSelect = true
              break
            }
          }
          selectItem.isAllCheck = !allSelect
        }


        this.setEditAloneItem(selectItem, code, isCheck)

      }


      this.$refs.list.setRowData(this.itemSource)
    },

    initColumns() {

      this.columns.forEach(item => {
        const waitOrder = ['rowIndex']
        if (!waitOrder.includes(item.field)) {
          item.cellRendererParams = {
            onChange: (data) => {

              this.onSetSelect(data)
            },
          };
        }
      })
      this.$refs.list.changeColumns(this.columns);
    },
  },
  created() {
    this.setData([])
    this.onInit()
  },
  mounted() {

  }
};
</script>
<style lang="scss">
.p-client-merge {
  padding: 20px;
  box-sizing: border-box;
  width: 1300px;

  .znl-input {
    margin-right: 10px;
  }

  .znl-border-input {
    margin-right: 10px;

    .znl-input-title {
      color: #888888;
    }
  }

  .c-merge-client_title {
    font-size: 16px;
    margin: 10px 0;
    color: #0a76a4;
  }


  .c-merge-client_box {
    width: 1300px;
  }


  .c-merge-client_content {
    margin-bottom: 10px;
    padding-left: 20px;
  }

  .c-merge-client_attribute {
    .znl-border-input {
      margin-bottom: 10px !important;
    }
  }

  .p-merge-client-footer {
    text-align: right;
  }
}
</style>
